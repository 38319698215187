<template>
	<div>
		<!-- 角色管理 -->
		<div class="queryBox mb20">
			<!-- <el-button size="mini" type="primary" icon="el-icon-plu" @click="addAccount('new')">{{$t('main.systemMenu.newlyAdded')}}</el-button> -->
			<el-input size="mini" placeholder="商户号" v-model="business_no" clearable class="ml40 mr20" style="width: 200px"></el-input><s></s>
			<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'" @click="getData" :disabled="disLoading">{{$t('main.orderMenu.query')}}</el-button>
		</div>
		<template>
		
			<div>
				<el-table :max-height="height" :data="tableData" border stripe v-loading="loading"  size="mini">
					<el-table-column :label="$t('main.reportMenu.serialNumber')" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.chat_id">{{scope.$index + 1}}</span>
							<!-- <span v-else="">{{scope.$index + 1}}</span> -->
						</template>
					</el-table-column>
					<el-table-column prop="chat_id" label="群id" align="center"></el-table-column>
					<el-table-column prop="chat_name" label="群组名称" align="center" show-overflow-tooltip></el-table-column>
					<el-table-column label="已绑商户" align="center">
						<template slot-scope="scope">
							<!-- <el-card class="box-card" shadow="never"> -->
							  <div v-for="business in scope.row.trader_list" :key="business.id" class="text item">
								[{{business.business_no}}]{{business.business_name}}
							  </div>
							<!-- </el-card> -->
						</template>
					</el-table-column>
					<el-table-column prop="operator" label="操作人" align="center" show-overflow-tooltip></el-table-column>
					<!-- <el-table-column prop="tra_prepaid" label="商户预付" align="center"></el-table-column> -->
					
					<!-- <el-table-column prop="payway_prepaid" label="通道预付" align="center"></el-table-column> -->
					
					<el-table-column prop="" :label="$t('main.passagewayMenu.operation')" align="center">
						<template slot-scope="scope" class="flexX flexcenter">
							  <!-- <el-button class="ml10 mr10 mb10" size="mini" @click="addAccount('bindTrader', scope.row)">绑定商户</el-button>
							  <el-button class="mr10 mb10" size="mini" @click="addAccount('bindPayway', scope.row)">绑定通道</el-button> -->
							  <el-button type="warning" class="mr10 mb10" size="mini" @click="delGroup(scope.row)">删除群组</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</template>
		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page="page" :page-sizes="pageSizesArr"
				:page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		<el-dialog center class="dialog" top="150px" :title="title" :visible.sync="dialogVisible" @close="close":close-on-click-modal="false">
			<div class="formBox" v-loading="dialogloading">
				<div>
					<div v-if="titleName" class="mb20">{{titleName}}</div>
					<el-form :label-position="labelPosition" label-width="100px" v-if="type === 'new'">
						<el-form-item label="角色名称">
							<el-input v-model="username" placeholder="请输入角色名称"></el-input>
						</el-form-item>
						
					</el-form>
					<el-form :label-position="labelPosition" label-width="100px" v-if="type === 'power'">
						<el-form-item label="角色名称">
							{{item.name}}
						</el-form-item>
						<el-form-item label="权限列表"></el-form-item>
						<div>
							<template>
							  <el-checkbox-group v-model="permName">
								<span class="powerItem" v-if="powerList.length>0" v-for="item in powerList"><el-checkbox :label="item.name"  :key="item.id"></el-checkbox></span>
								
							  
							  </el-checkbox-group>
							</template>
						</div>
					</el-form>
					
					<el-form v-if="type ==='bindTrader'" :label-position="labelPosition" label-width="120px" class="mb20">
						  <div style="margin: 15px 0;"></div>
						  <el-checkbox-group  v-model="checkedTraders"  @change="handleCheckedTraderChange">
						    <el-checkbox v-for="tra in traders" :label="tra.id" :key="tra.id">{{tra.business_name}}</el-checkbox>
						  </el-checkbox-group>
					</el-form>
					
					<el-form v-if="type ==='bindPayway'" :label-position="labelPosition" label-width="120px" class="mb20">
						  <div style="margin: 15px 0;"></div>
						  <el-checkbox-group  v-model="checkedPayways"  @change="handleCheckedPaywayChange">
						    <el-checkbox v-for="pay in payways" :label="pay.id" :key="pay.id">{{pay.name}}</el-checkbox>
						  </el-checkbox-group>
					</el-form>
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmit">{{submitText}}</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				dialogloading: false,
				dialogVisible: false,
				date: [],
				input: '',
				tableData: [],
				dataTotal: {},
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				item:{},
				//新增
				labelPosition: 'right',
				username:'',
				password:'',
				passwordSure: '', //确认密码
				type: '', //new  新增   roles配置角色
				title: '',
				titleName: '',
				submitText: '新增',
				roles:'',//角色
				powerList:[],//角色列表
				permName:[],
				rolePageSize:100,
				height:0,
				// 绑定商户
				traders: [],
				checkedTraders: [],
				submitTrders: [],
				// 绑定通道
				payways: [],
				checkedPayways: [],
				submitPayways: [],
				business_no:''
				
			}
		},
		created() {
			this.getChatList()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 40 - 60
				console.log(this.height)
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			//搜索按钮
			getData() {
				this.page = 1;
				this.getChatList();
			},
			
			//角色列表
			getChatList(){
				this.$api.chatList({
					type: 1,
					page: this.page,
					size: this.pageSize,
					business_no: this.business_no
				}).then(res => {
					if (res.status === 1) {
						this.tableData = res.data.data	
						this.total = res.data.total	
						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
				})
			},
			// 获取绑定商户列表
			getChatTraderList(itemid){
				this.$api.chatTraderList({
					id: itemid
				}).then(res => {
					if (res.status === 1) {
						this.checkedTraders = []
						this.traders = res.data		
						let cur_data = res.data
						for(let k = 0;k<cur_data.length;k++){
							if(cur_data[k].is_bind === 1){
								this.checkedTraders.push(cur_data[k].id)
							}
						}
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
				})
			},
			// 获取绑定通道列表
			getChatPaywayList(itemid){
				this.$api.chatPaywayList({
					id: itemid
				}).then(res => {
					if (res.status === 1) {
						this.checkedPayways = []
						this.payways = res.data		
						let cur_data = res.data
						for(let k = 0;k<cur_data.length;k++){
							if(cur_data[k].is_bind === 1){
								this.checkedPayways.push(cur_data[k].id)
							}
						}
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
				})
			},
		    handleCheckedTraderChange(value) {
				
				let traderList = []
				let tcs = this.traders
				for(let k = 0;k<tcs.length;k++){
					for(let j = 0;j<value.length;j++){
						if(tcs[k].id === value[j]){
							traderList.push(tcs[k]['business_no'])
						}
					}
				}
				
				this.submitTrders = traderList
		    },
		    handleCheckedPaywayChange(value) {
				
				let paywayList = []
				let tcs = this.payways
				for(let k = 0;k<tcs.length;k++){
					for(let j = 0;j<value.length;j++){
						if(tcs[k].id === value[j]){
							paywayList.push(tcs[k]['id'])
						}
					}
				}
				
				this.submitPayways = paywayList
		    },
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.page = 1
				this.pageSize = val
				this.getChatList()
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.page = val
				this.getChatList()
			},
			addAccount(type,item) {
				this.dialogVisible = true
				this.permName = []
				this.type = type
				if (item) {
					this.item = item;
				}
				if (this.type === 'new') {
					this.title = '新增角色'
					this.submitText = '新增'
				}
				else if(this.type == 'power'){
					for(let i = 0;i<this.item.permissions.length;i++){
						for(let j = 0; j<this.powerList.length;j++){
							if(this.item.permissions[i] == this.powerList[j].id){
								this.permName.push(this.powerList[j].name)
							}
						}
					}
					this.roles = this.item.role_id
					this.title = '设置用户权限'
					this.submitText = '确定'
				}
				else if(this.type == 'bindTrader'){
					this.getChatTraderList(this.item.id)
					this.title = '绑定商户'
					this.submitText = '确定'
				}
				else if(this.type == 'bindPayway'){
					this.getChatPaywayList(this.item.id)
					this.title = '绑定通道'
					this.submitText = '确定'
				}
			},
			close() {
				this.dialogVisible = false
				this.type="";
				this.username="";
				this.item = {};
				this.permName=[]
				this.traders=[]
				this.payways=[]
			},
			//新增
			onSubmit() {
				if (this.type === 'new') {
					//新增商家
					if (isEmpty(this.username, '请输入角色名称')) {
						return
					}
					
					let data = {
						name: this.username
					}
					this.dialogloading = true
					this.$api.addRole(data).then(res => {
						// console.log(res)
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							});
							this.close();
							this.page = 1;
							this.getChatList()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
					}).catch(error => {
						this.dialogloading = false
					})
				}
				else if(this.type == 'power'){
					let permId=[];
					for(let i = 0;i<this.permName.length;i++){
						for(let j = 0; j<this.powerList.length;j++){
							if(this.permName[i] == this.powerList[j].name){
								permId.push(this.powerList[j].id)
							}
						}
					}
					let data ={
						role_id:this.item.id,
						permission_id:JSON.stringify(permId)
					}
					console.log(data)
					this.configRolePermission(data)
				}
				else if(this.type == 'bindTrader'){
					let data ={
						id:this.item.id,
						trader_list:this.submitTrders.join('|')
					}
					this.configBindTraderPayway(data)
				}
				else if(this.type == 'bindPayway'){
					let data ={
						id:this.item.id,
						payway_list:this.submitPayways.join('|')
					}
					this.configBindTraderPayway(data)
				}
			},
			//删除群组
			delGroup(item){
				this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
					confirmButtonText: this.$t('main.common.submit'),
					cancelButtonText: this.$t('main.common.cancel'),
					type: 'warning'
				}).then(() => {
					this.loading = true;
					let data = {
						id: item.id,
					}
					this.$api.deleteChat(data).then(res => {
						this.loading = false;
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.getChatList()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.loading = false;
					})
				
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消删除群组`
					});
				});
			},
			//设置权限
			configBindTraderPayway(data){
				this.dialogloading = true
				this.$api.editChat(data).then(res=>{
					if(res.status == 1){
						this.dialogloading = false
						this.close()
						this.getChatList()
						this.$message({type:"success",message:res.msg})
					} else{						
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.dialogloading = false
				})
			},
			
		},
		watch: {
			total1(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 40 - 60
					console.log(this.height)
				})
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.formBox {
		width: 80%;
		margin: 0 auto;
	}
	.powerItem{
		display: inline-block;
		width: 33%;
		text-align: left;
		margin-bottom: 20px;
	}
	/* 滚动条宽度 */
	  ::-webkit-scrollbar {
	       width: 0px;
	   }
	   table tbody {
	       display: block;
	       overflow-y: auto;
	       -webkit-overflow-scrolling: touch;
			border-bottom: solid 1px #ddd;
	   }	 
	   table tbody tr:last-child td{
			border-bottom: none;
	   }	 
	   table thead tr, table tbody tr, table tfoot tr {
	       box-sizing: border-box;
	       table-layout: fixed;
	       display: table;
	       width: 100%;
	   }
	   table td{
		   word-break:break-all;
	   }
</style>
